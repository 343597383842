import { reactive } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import type { MessageInterface } from '@voix/types'

export const messages: Array<MessageInterface> = reactive([])

export function useVoixToast() {
  const defaultTimeout = 5000

  const remove = (messageId: string) => {
    const messageToRemove = messages.find(message => message.id === messageId)
    if (!messageToRemove)
      return false
    messages.splice(messages.indexOf(messageToRemove), 1)
  }

  class Message implements MessageInterface {
    id: string
    msg: string
    type: 'info' | 'msg' | 'error' | 'warning'
    expires: Date | null
    timeout: number // Milliseconds until the message expires

    constructor(
      id: string,
      msg: string,
      type: 'info' | 'msg' | 'error' | 'warning',
      expires: Date | null,
      timeout: number,
    ) {
      this.id = id
      this.msg = msg
      this.type = type
      this.expires = expires
      this.timeout = timeout
      this.selfDestruct()
    }

    // Return the number of seconds until the message expires
    get timeLeft() {
      if (this.expires)
        return dayjs(this.expires).diff(dayjs(), 'second')

      return null
    }

    // Add time to the expiry date
    setExpires(newDate: Date) {
      if (this.expires) {
        this.expires = newDate
        return true
      }

      return false
    }

    selfDestruct = () => {
      const interval = setInterval(() => {
        // If the time has expired then remove the message
        if (dayjs(this.expires).isBefore(dayjs())) {
          remove(this.id)
          clearInterval(interval)
        }
      }, 250)
    }
  }

  const add = (
    msg: string,
    type: 'info' | 'msg' | 'error' | 'warning',
    timeout?: number,
  ): MessageInterface => {
    const messageId = uuidv4()

    // If timeout isn't set for -1 then remove the message after the timeout or 5 seconds
    let expires = null
    if (timeout !== -1) {
      // Set the expiry date to now + the timeout
      expires = dayjs()
        .add(timeout || defaultTimeout, 'millisecond')
        .toDate()
    }

    const message = new Message(
      messageId,
      msg,
      type,
      expires,
      timeout || defaultTimeout,
    )
    messages.push(message)

    return message
  }

  return { messages, add, remove, defaultTimeout }
}
