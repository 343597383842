import { useAdminStore } from '@voix/store/adminStore'
import { useVoixToast } from '@voix/composables/useVoixToast'
import { useSiteUtils } from '@voix/composables/useSiteUtils'
import { useAsyncData, useCookie, useRequestHeaders, useRequestURL, useRuntimeConfig } from '#imports'

export async function $voixNuxtApi(request: string, options: { [key: string]: any } = {}) {
  const config = useRuntimeConfig()

  const { getCurrentDomain } = useSiteUtils()
  const domain = getCurrentDomain()

  const headers: { [key: string]: string } = {
    'X-VOIX-SITE': domain,
    'accept': 'application/json',
  }
  const adminStore = useAdminStore()
  const releasePreviewHeader = adminStore.releaseId
  if (releasePreviewHeader)
    headers['X-VOIX-RELEASE-PREVIEW'] = releasePreviewHeader
  const accessToken = useCookie('auth-access-token')
  if (accessToken.value)
    headers.authorization = `Bearer ${accessToken.value}`

  // We want to invalidate the cache if we're doing a POST or PUT request
  const randomString = Math.random().toString(36).substring(7)
  const requestKey = options.method && (options.method.toLowerCase() === 'put' || options.method.toLowerCase() === 'post') ? request + randomString : request

  console.log(`${config.public.voixApiBaseUrl}${request}`, headers)
  // Make the request
  const theRequest = await useAsyncData(
    requestKey,
    () => $fetch(
      `${config.public.voixApiBaseUrl}${request}`,
      {
        ...options,
        headers: {
          ...headers,
          ...options?.headers,
        },
      },
    ),
  )
  const errorValue = theRequest.error.value

  if (errorValue) {
    const voixToast = useVoixToast()

    if (errorValue.data) {
      switch (errorValue.data.statusCode) {
        case 401:
          console.warn('Voix Nuxt API: Route to login')
          break
        case 422:
          if (errorValue.data.data?.errors) {
            Object.keys(errorValue.data.data?.errors).forEach((key) => {
              errorValue.data.data?.errors[key].forEach((validationError) => {
                voixToast.add(validationError, 'error')
              })
            })
          }
          else {
            voixToast.add(errorValue.data.message, 'error')
          }
          break
        default:
          console.error('Voix Nuxt API:', theRequest.error)
          voixToast.add(errorValue.data.message, 'error')
          break
      }
    }
    else {
      console.error('Voix Nuxt API:', errorValue)
    }
  }

  // Allow directly awaiting on asyncData
  const asyncDataPromise = Promise.resolve(theRequest).then(() => theRequest)
  Object.assign(asyncDataPromise, theRequest)

  return asyncDataPromise
}
