import { useRequestHeaders, useRequestURL, useRuntimeConfig } from '#imports'

export function useSiteUtils() {
  const getCurrentDomain = () => {
    const config = useRuntimeConfig()
    let domain = config.public.voixDomain

    if (config.public.voixEnv !== 'local' && config.public.voixEnv !== 'development') {
      const hostHeaderName = config.public?.voixHostHeader ?? 'host'
      const requestHeaders = useRequestHeaders()
      if (Object.prototype.hasOwnProperty.call(requestHeaders, hostHeaderName)) {
        domain = requestHeaders[hostHeaderName]
      }
      else {
        const requestUrl = useRequestURL()
        if (requestUrl && requestUrl.host)
          domain = requestUrl.host
      }
    }

    return domain
  }

  return {
    getCurrentDomain,
  }
}
